import React, { useEffect, useState } from 'react';
import { CrossIcon } from '../../img/icons/CrossIcon';

interface ShelterData {
  id: number;
  name?: string;
  address: string;
  fact_capacity: number;
  building_type: string;
  photos?: string[];
}

interface ShelterModalProps {
  isOpen: boolean;
  onClose: () => void;
  shelterData: ShelterData | null;
  onRoute: () => void;
}

const ShelterModal: React.FC<ShelterModalProps> = ({
  isOpen,
  onClose,
  shelterData,
  onRoute,
}) => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [isPhotoFullscreen, setIsPhotoFullscreen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const serverBasePath = 'https://bomb.fdev.by';

  const toggleFullscreen = () => {
    setIsPhotoFullscreen(!isPhotoFullscreen);
  };

  const handlePrevClick = () => {
    if (isPhotoFullscreen) {
      toggleFullscreen();
    } else if (shelterData && shelterData.photos) {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : shelterData.photos!.length - 1,
      );
    }
  };

  const handleNextClick = () => {
    if (isPhotoFullscreen) {
      toggleFullscreen();
    } else if (shelterData && shelterData.photos) {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex < shelterData.photos!.length - 1 ? prevIndex + 1 : 0,
      );
    }
  };

  if (!isOpen || !shelterData) return null;

  return (
    <div className="fixed bottom-40 right-0 z-50 overflow-auto p-4">
      <div className="bg-white border-[3px] border-main-blue border-solid rounded-lg shadow-lg max-h-full w-120 p-5 relative flex flex-col">
        <button
          onClick={onClose}
          className="absolute top-1 right-2 rounded-full w-10 h-10 flex justify-center items-center"
        >
          <CrossIcon />
        </button>
        <h3 className="mt-3 text-md font-medium text-gray-900 text-center">
          "Ближайшее к вам ЗПП"
        </h3>

        <div className="text-sm text-gray-500">
          <p>{shelterData.address}</p>
          <p>Вместимость: {shelterData.fact_capacity}</p>
          <p>{shelterData.building_type}</p>
        </div>

        {shelterData.photos && shelterData.photos.length > 0 && (
          <div className="flex justify-center items-center mt-3">
            {isPhotoFullscreen ? (
              <div
                className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-90 z-50"
                onClick={toggleFullscreen}
              >
                <img
                  //@ts-ignore
                  src={`${serverBasePath}${shelterData.photos[currentPhotoIndex]}`}
                  alt={`Фото убежища ${currentPhotoIndex + 1}`}
                  className="max-h-full max-w-full cursor-pointer"
                />
              </div>
            ) : (
              <>
                <img
                  //@ts-ignore
                  src={`${serverBasePath}${shelterData.photos[currentPhotoIndex]}`}
                  alt={`Фото убежища ${currentPhotoIndex + 1}`}
                  className={`w-50 h-60 my-3 ${
                    isPhotoFullscreen ? 'cursor-pointer' : ''
                  }`}
                  style={{ objectFit: 'cover' }}
                  onClick={toggleFullscreen}
                />
                <button
                  onClick={handlePrevClick}
                  className="absolute left-0 top-1/2 transform -translate-y-1/2"
                >
                  &#10094;
                </button>
                <button
                  onClick={handleNextClick}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2"
                >
                  &#10095;
                </button>
              </>
            )}
          </div>
        )}
        
        <div className="flex justify-center mt-4">
          <button
            onClick={() => {
              onRoute();
              onClose();
            }}
            className="w-full py-2 text-white font-medium bg-main-blue rounded-md"
          >
            Проложить маршрут
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShelterModal;
