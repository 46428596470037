import { useState, useEffect } from 'react';
import { CrossIcon } from '../../img/icons/CrossIcon';
import { CustomInput } from '../CustomInput';
import CustomCheckbox from '../CustomCheckbox';

type Props = {
  setIsFeedbackModalOpened: (bool: boolean) => void;
};

export const FeedbackFormModal = ({ setIsFeedbackModalOpened }: Props) => {
  const [fioInputValue, setFioInputValue] = useState('');
  const [phoneNumberInputValue, setPhoneNumberInputValue] = useState('');
  const [emailInputValue, setEmailInputValue] = useState('');
  const [messageInputValue, setMessageInputValue] = useState('');
  const [isAgreementChecked, setIsAgreementChecked] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setIsSmallScreen(width <= 767 && height <= 2360);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const handleSubmit = async () => {
    if (!isAgreementChecked) {
      setErrorMessage('Пожалуйста, примите Пользовательское соглашение.');
      return;
    }

    setIsSending(true);
    setErrorMessage('');

    try {
      const response = await fetch('https://bomb.fdev.by/api/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fioInputValue,
          phoneNumberInputValue,
          emailInputValue,
          messageInputValue,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setIsFeedbackModalOpened(false);
      } else {
        setErrorMessage(data.message || 'Ошибка при отправке. Попробуйте снова.');
      }
    } catch (error) {
      setErrorMessage('Ошибка при отправке. Попробуйте снова.');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div
      onClick={() => setIsFeedbackModalOpened(false)}
      className={`fixed inset-0 ${isSmallScreen ? '' : 'bg-popup-bg'} cursor-pointer`}
    >
      <div
        className={`min-h-full flex justify-center ${isSmallScreen ? 'items-start pt-10' : 'items-center p-10 pt-6'} cursor-default`}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={`bg-white max-w-[490px] ${isSmallScreen ? 'w-full py-5 px-8 rounded-2xl' : 'w-full py-5 px-8 rounded-2xl'}`}
          style={isSmallScreen ? { transform: 'translateY(-130%)' } : {}}
        >
          <div
            className="mr-0 ml-auto max-w-max cursor-pointer"
            onClick={() => setIsFeedbackModalOpened(false)}
          >
            <CrossIcon />
          </div>
          <h1 className="text-xl font-medium">Написать сообщение</h1>
          <p className="text-base font-normal text-[#918F93] pt-3">
            Опишите суть вашей проблемы или предложения.
          </p>
          {/* Input Fields */}
          <div className="mt-5">
            <CustomInput
              inputValue={fioInputValue}
              setInputValue={setFioInputValue}
              label="ФИО"
            />
          </div>
          <div className="mt-3">
            <CustomInput
              inputValue={phoneNumberInputValue}
              setInputValue={setPhoneNumberInputValue}
              label="Номер телефона"
            />
          </div>
          <div className="mt-3">
            <CustomInput
              inputValue={emailInputValue}
              setInputValue={setEmailInputValue}
              label="Электронный адрес"
            />
          </div>
          <div className="mt-3">
            <input
              value={messageInputValue}
              onChange={(e) => setMessageInputValue(e.target.value)}
              className="w-full p-4 border-[#79747E] border-[1px] rounded-[4px] pb-[80px] outline-none"
              placeholder="Суть проблемы или предложения"
            />
          </div>
          {/* Agreement Checkbox */}
          <div className="flex items-start mt-3">
            <CustomCheckbox
              isChecked={isAgreementChecked}
              setIsChecked={setIsAgreementChecked}
            />
            <span className="ml-3 text-sm leading-6 opacity-60">
              Я принимаю{' '}
              <a
                href="http://bezopasnost112.ru/polzovatelskoe-soglashenie"
                target="_blank"
                className="text-base"
                rel="noreferrer"
              >
                Пользовательское соглашение
              </a>
            </span>
          </div>
          {/* Error Message Display */}
          {errorMessage && (
            <div className="text-red-500 mt-2">{errorMessage}</div>
          )}
          {/* Submit Button */}
          <button
            disabled={isSending}
            onClick={handleSubmit}
            className="w-full py-[10px] text-white font-medium bg-main-blue rounded-[4px] mt-3"
          >
            {isSending ? 'Отправка...' : 'Отправить'}
          </button>
        </div>
      </div>
    </div>
  );
};
