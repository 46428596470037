
import React, { useState, useEffect, useRef } from 'react';
import './styles/App.css';
import {
  YMaps,
  Map,
  Placemark,
  ZoomControl,
  ObjectManager,
  RoutePanel,
  RouteEditor,
  GeolocationControl,
  YMapsApi,
} from 'react-yandex-maps';
import axios from 'axios';
import { SearchIcon } from './img/icons/SearchIcon';
import { RoundedCrossIcon } from './img/icons/RoundedCrossIcon';
import customPlacemark from './img/customPlacemark.svg';
import shelterPlaceholder from './img/shelterPlaceholder.svg';
import hintImage from './img/hint.svg';
import bombShelterImage from './img/bombshelter.svg';
import userLocationPlacemark from './img/userLocationPlacemark.svg';
import { YANDEX_API_KEY } from './consts';
import { getAdressByCoors } from './utils/getAdressByCoords';
import { FeedbackFormModal } from './components/Modals/FeedbackFormModal';
import ShelterModal from '../src/components/Modals/ShelterModal';
import { AnimatePresence, motion } from 'framer-motion';
import CustomHint from './components/CustomHint';

export interface Coordinate {
  latitude: number;
  longitude: number;
}

interface ShelterInfo {
  id: number;
  name: string;
  address: string;
  building_type: string;
  fact_capacity: number;
  latitude: number;
  longitude: number;
  // Другие свойства, если они есть
}

function App() {
  const [shelterMarker, setShelterMarker] = useState<Coordinate | null>(null);

  const [shelterInfo, setShelterInfo] = useState<ShelterInfo | null>(null);

  const buildRoute = () => {
    if (!ymaps || !userLocation || !shelterInfo) {
      console.error('Необходимые данные для построения маршрута отсутствуют');
      return;
    }

    // Если уже есть маршрут, удалите его
    if (route) {
      mapRef.current.geoObjects.remove(route);
    }

    // Создание мультимаршрута
    const multiRoute = new ymaps.multiRouter.MultiRoute(
      {
        // Точки маршрута: [широта, долгота]
        referencePoints: [
          userLocation, // Местоположение пользователя
          [shelterInfo.latitude, shelterInfo.longitude], // Местоположение убежища
        ],
        params: {
          // Параметры маршрутизации
        },
      },
      {
        // Визуальные настройки маршрута
        routeActiveStrokeColor: '#0173C1',
        routeActiveStrokeWidth: 6,
      },
    );

    // Добавление мультимаршрута на карту
    mapRef.current.geoObjects.add(multiRoute);

    // Сохранение ссылки на мультимаршрут
    setRoute(multiRoute);
  };

  const [isShelterModalOpen, setIsShelterModalOpen] = useState(false);

  const mapRef = useRef<any>(null);
  const [ymaps, setYmaps] = useState<YMapsApi | null>(null);
  const [isHintShown, setIsHintShown] = useState(true);
  const [searchAddress, setSearchAddress] = useState('');
  const [mapCenter, setMapCenter] = useState<Coordinate>({
    latitude: 55.1604,
    longitude: 61.4,
  });
  const [mapZoom, setMapZoom] = useState(13);
  // Выбор локации по клику на карту
  const [selectedLocation, setSelectedLocation] = useState<number[]>();

  useEffect(() => {
    selectedLocation && getAdressByCoors(selectedLocation);
  }, [selectedLocation]);

  // Поиск по адресу
// Функция для выполнения геокодирования
//@ts-ignore
const geocodeAddress = async (address) => {
  try {
    const response = await axios.post('https://bomb.fdev.by/api/geocoder', {
      address: address,
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при выполнении геокодирования:', error);
    throw error;
  }
};

// Функция для поиска убежища по координатам
//@ts-ignore
const findShelterByCoordinates = async (latitude, longitude) => {
  try {
    const response = await axios.post('https://bomb.fdev.by/api/findShelter', {
      latitude: latitude,
      longitude: longitude,
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при поиске убежища:', error);
    throw error;
  }
};

// Обработчик события при вводе адреса в строку поиска
//@ts-ignore
const handleSearch = async (address: string) => {
  try {
    // Выполните геокодирование, чтобы получить координаты
    const geoData = await geocodeAddress(address);
    
    if (geoData && geoData.latitude && geoData.longitude) {
      // Если есть координаты, выполните поиск убежища

      const response = await axios.post(
        'https://bomb.fdev.by/api/findshelter',
        {
          latitude:geoData.latitude,
          longitude: geoData.longitude,
        },
      );
      
      // Обновите состояние вашего приложения с информацией об убежище и координатах
      setShelterInfo(response.data);
      setShelterMarker({
        latitude: response.data.latitude,
        longitude: response.data.longitude,
      });
      setIsShelterModalOpen(true);
    } else {
      console.error('Координаты не найдены');
    }
  } catch (error) {
    console.error('Ошибка при поиске адреса или убежища:', error);
  }
};

  useEffect(() => {
    updateMap();
  }, [mapCenter, mapZoom, selectedLocation]);

  const updateMap = () => {};

  const [userLocation, setUserLocation] = useState<number[] | null>(null);

  // Запрос местоположения пользователя
  // //тестовое
  // const [userLocation, setUserLocation] = useState<number[] | null>([
  //   55.139805, 61.38494,
  // ]);
  // const [isBookShelterFormModalVisible, setIsBookShelterFormModalVisible] =
  //   useState(false);
  //Реальное
  const getUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation([latitude, longitude]);
        console.log([latitude, longitude]);
      });
    }
  };

  useEffect(() => {
    getUserLocation();
    const intervalId = setInterval(getUserLocation, 10000);

    return () => clearInterval(intervalId);
  }, []);

  // Пролаживание маршрута от пользователя до метки
  const [route, setRoute] = useState<any>(null);
  const routes = useRef(null);
  const [isRouteShown, setIsRouteShown] = useState(false);

  const getRoutes = () => {
    // @ts-ignore
    console.log(routes?.current?.getWayPoints());
  };

  const getRoute = (ref: any) => {
    if (ymaps) {
      if (routes.current) {
        ref.geoObjects.remove(routes.current);
      }

      const multiRoute = new ymaps.multiRouter.MultiRoute(
        {
          referencePoints: [userLocation, selectedLocation?.reverse()],
          params: {
            results: 3,
            routingOptions: {
              avoidTrafficJams: true,
              routingPreference: 'shortest',
            },
          },
        },
        {
          boundsAutoApply: true,
          routeActiveStrokeWidth: 6,
          routeActiveStrokeColor: '#0173C1',
        },
      );

      routes.current = multiRoute;
      ref.geoObjects.add(multiRoute);
    }
  };

  const [isFeedbackModalOpened, setIsFeedbackModalOpened] = useState(false);

  const handleFindShelter = async () => {
    if (userLocation) {
      try {
        // Предполагается, что userLocation - это массив с двумя числами: широтой и долготой
        const response = await axios.post(
          'https://bomb.fdev.by/api/findshelter',
          {
            latitude: userLocation[0],
            longitude: userLocation[1],
          },
        );
        // Сохраните полученные данные об убежище в состояние
        setShelterInfo(response.data);
        setShelterMarker({
          // Устанавливаем маркер убежища
          latitude: response.data.latitude,
          longitude: response.data.longitude,
        });
        // Откройте модальное окно
        setIsShelterModalOpen(true);
      } catch (error) {
        console.error('Ошибка при запросе к API:', error);
      }
    } else {
      console.error('Местоположение пользователя не определено');
    }
  };

  const buildRouteAndCloseModal = () => {
    buildRoute(); // Построение маршрута
    setIsShelterModalOpen(false); // Закрытие модального окна
  };

  return (
    <div className="relative ">
      <div className={`bg-main-blue py-[30px] px-[50px] absolute w-full z-10 ${isFeedbackModalOpened ? 'hidden md:block' : 'block'}`}>
  <h1 className="text-[25px] text-white text-center md:text-left">
    Карта укрытий города Челябинска
  </h1>
</div>
      <div className="relative ">
        <div className="absolute z-10 top-[110px] left-1/2 transform -translate-x-1/2 md:left-[52px] md:transform-none">
          <div className="relative flex items-center w-[400px]">
            {/* <input
              type="text"
              placeholder="Найти по адресу"
              value={searchAddress}
              onChange={(e) => {
                setSearchAddress(e.target.value);
                // setTimeout(() => {
                //   handleSearch(e.target.value);
                // }, 1000);
              }}
              onKeyDown={(e) =>
                e.key === 'Enter' && handleSearch(searchAddress)
              }
              className="max-w-[400px] w-full py-3 px-2 pl-10 border-[3px] border-main-blue border-solid rounded"
            /> */}
            <input
  type="text"
  placeholder="Найти по адресу"
  value={searchAddress}
  onChange={(e) => setSearchAddress(e.target.value)}
  onKeyDown={(e) => e.key === 'Enter' && handleSearch(searchAddress)}
  className={`max-w-[400px] w-full py-3 px-2 pl-10 border-[3px] border-main-blue border-solid rounded ${isFeedbackModalOpened ? 'hidden md:block' : 'block'}`}
/>
<div
  className={`absolute top-[16px] left-2 cursor-pointer ${isFeedbackModalOpened ? 'hidden md:block' : 'block'}`}
  onClick={() => handleSearch(searchAddress)}
>
  <SearchIcon />
</div>
            {searchAddress && (
              <div
                className="absolute top-[16px] right-2 cursor-pointer"
                onClick={() => setSearchAddress('')}
              >
                <RoundedCrossIcon />
              </div>
            )}
          </div>
          <div>
            <div>{isHintShown && <CustomHint />}</div>
          </div>
          {/* <img className="max-w-[400px] mt-2" src={photoUrl} /> */}
        </div>

        <YMaps query={{ apikey: YANDEX_API_KEY }}>
          <Map
            modules={[
              'multiRouter.MultiRoute',
              'control.GeolocationControl',
              'geolocation',
              'geocode',
            ]}
            onLoad={(ymaps) => setYmaps(ymaps)}
            instanceRef={(ref) => (mapRef.current = ref)}
            onClick={(e: any) => {
              const coords = e.get('coords');
              console.log(coords);
              setSelectedLocation(coords);
            }}
            defaultState={{
              center: [mapCenter.latitude, mapCenter.longitude],
              zoom: mapZoom,
            }}
            width="100%"
            height="100vh"
            key={`${mapCenter.latitude}-${mapCenter.longitude}-${mapZoom}`}
          >
            <div className="relative">
              <Placemark
                geometry={selectedLocation}
                options={{
                  iconLayout: 'default#image',
                  iconImageHref: customPlacemark,
                  iconImageSize: [30, 43],
                }}
                modules={['geoObject.addon.balloon']}
              />
              {/* <div className="bg-white absolute flex justify-center items-center top-0 left-[50%] z-20">
                FFDFDFF
              </div> */}
            </div>
            <div className="relative">
              {' '}
              {shelterMarker && (
                <Placemark
                  geometry={[shelterMarker.latitude, shelterMarker.longitude]}
                  options={{
                    iconLayout: 'default#image',
                    iconImageHref: shelterPlaceholder,
                    iconImageSize: [32, 32],
                  }}
                />
              )}
            </div>

            {userLocation && (
              <Placemark
                geometry={userLocation}
                options={{
                  iconLayout: 'default#image',
                  iconImageHref: userLocationPlacemark,
                  iconImageSize: [32, 32],
                }}
              />
            )}
            {/* <RoutePanel options={{ allowSwitch: false }} /> */}
            <ZoomControl
              options={{
                size: 'medium',
                position: {
                  right: '10px',
                  top: '50vh',
                  margin: 'auto',
                },
              }}
            />
            <GeolocationControl
              options={{
                size: 'medium',
                position: {
                  right: '10px',
                  top: 'calc(50vh + 66px)',
                },
              }}
            />
            <div
              className="absolute bg-white z-10 right-[10px] top-[calc(50vh+99px)] w-[28px] h-[28px] bg-center bg-contain cursor-pointer rounded shadow-lg"
              style={{ backgroundImage: `url(${hintImage})` }}
              onClick={() => setIsHintShown(!isHintShown)}
            />
            {/* <div
              className="absolute bg-white z-10 right-[10px] top-[calc(50vh+132px)] w-[28px] h-[28px] bg-center bg-contain cursor-pointer rounded shadow-lg"
              style={{ backgroundImage: `url(${bombShelterImage})` }}
              onClick={handleFindShelter}
            /> */}
          </Map>
        </YMaps>
      </div>

      {isShelterModalOpen && shelterInfo && (
        <div className="bottom-4 right-4 z-50">
          <ShelterModal
            isOpen={isShelterModalOpen}
            onClose={() => setIsShelterModalOpen(false)}
            shelterData={shelterInfo}
            onRoute={buildRoute}
          />
        </div>
      )}

      <div className="flex justify-center md:justify-start absolute bottom-[60px] left-1/2 md:left-[52px] transform md:transform-none -translate-x-1/2 slide-in">
        <a
          href="https://bezopasnost112.ru/"
          className="py-[10px] px-6 bg-main-blue rounded text-sm font-medium text-white mr-4 text-center button-animation slide-in"
        >
          Вернуться на сайт
        </a>
        <button
  onClick={() => {
    handleFindShelter(); // Call the function to handle the API request
  }}
  className="py-[10px] px-6 bg-white text-black border-2 border-main-blue rounded text-sm font-medium flex items-center justify-center button-animation slide-in ml-4 mr-6" // Added margin-right class (mr-4)
>
  <img src={bombShelterImage} alt="Icon" style={{ height: '20px', marginRight: '10px' }} />
  Показать маршрут
</button>
        <button
          onClick={() => setIsFeedbackModalOpened(true)}
          className="py-[10px] px-6 bg-main-blue rounded text-sm font-medium text-white button-animation slide-in"
        >
          Связаться с нами
        </button>
        {/* <button
  onClick={() => {
    handleFindShelter(); // Call the function to handle the API request
  }}
  className="py-[10px] px-6 bg-white rounded text-sm font-medium text-blue ml-4 button-animation slide-in"
>
  Показать маршрут
</button> */}


        <AnimatePresence mode="wait">
          {isFeedbackModalOpened && (
            <div className="fixed inset-0 z-50 flex justify-center items-center">
              <motion.div
                style={{ zIndex: 100 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.3 } }}
                exit={{ opacity: 0, transition: { duration: 0.3 } }}
              >
                <FeedbackFormModal
                  setIsFeedbackModalOpened={setIsFeedbackModalOpened}
                />
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </div>
      {/* <div className="absolute bottom-0 w-full bg-white"></div> */}
    </div>
  );
}

export default App;
