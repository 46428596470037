import React, { useId } from 'react';
import { RoundedCrossIcon } from '../img/icons/RoundedCrossIcon';

type Props = {
  inputValue: string;
  setInputValue: (val: string) => void;
  label: string;
};

export const CustomInput = ({ inputValue, setInputValue, label }: Props) => {
  const id = useId();

  return (
    <div className="relative w-full">
      <input
        id={id}
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        className={`border focus:outline-none focus:border-blue-500 py-2 px-4 w-full rounded-[4px] ${
          inputValue ? 'border-main-blue' : 'border-[#79747E]'
        }`}
      />
      <label
        htmlFor={id}
        className={`text-[#49454F] absolute transition-all  max-w-max duration-300 w-full cursor-text ${
          inputValue
            ? 'text-xs -top-2 left-2 text-main-blue bg-white px-[3px]'
            : 'text-base top-2 left-4 text-gray-500 '
        }`}
      >
        {label}
      </label>

      {inputValue && (
        <div
          onClick={() => setInputValue('')}
          className="absolute top-2 right-4 cursor-pointer"
        >
          <RoundedCrossIcon />
        </div>
      )}
    </div>
  );
};
