import React, { useState } from 'react';
import { RoundedCrossIcon } from '../img/icons/RoundedCrossIcon';

const CustomHint: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  if (!isVisible) return null;

  return (
    <div className="bg-white border-[3px] border-main-blue border-solid rounded p-2 rounded-lg shadow-md mt-4 max-w-[400px] w-full h-86 relative">
      <button
        onClick={() => setIsVisible(false)}
        className="absolute top-2 right-2 rounded-full w-10 h-10 flex justify-center items-center"
      >
        <RoundedCrossIcon />
      </button>
      <div className="p-6 rounded-lg">
        <h2 className="text-xl font-medium">
          Если вы находитесь в частном доме (в радиусе более 1 км от
          многоквартирных домов)
        </h2>
        <p className="text-base font-normal text-[#918F93] pt-3">
          Спуститесь в подвальное помещение. С собой возьмите документы
          удостоверяющие личность, телефоны, радио на батарейках, фонарь, воду,
          аптечку. Если погреба в вашем доме нет — выберите место рядом с
          несущей стеной. От окон и улицы вас должны отделять две стены.
        </p>
      </div>
    </div>
  );
};

export default CustomHint;
