import { YANDEX_API_KEY } from '../consts';
import axios from 'axios';

export const getAdressByCoors = async (location: number[]) => {
  const apiUrl = `https://geocode-maps.yandex.ru/1.x/?apikey=${YANDEX_API_KEY}&format=json&geocode=${location
    .reverse()
    .join(',')}`;

  const response = await axios.get(apiUrl);

  const data = response.data;
  const detailedAddress =
    data.response.GeoObjectCollection.featureMember[0].GeoObject
      .metaDataProperty.GeocoderMetaData.text;

  console.log(
    'Адрес:',
    data.response.GeoObjectCollection.featureMember[0].GeoObject.name,
  );
  console.log(
    'Full adress: ',
    data.response.GeoObjectCollection.featureMember[0].GeoObject
      .metaDataProperty.GeocoderMetaData.kind,
  );
};
