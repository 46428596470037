import React from 'react';

type Props = {
  isChecked: boolean;
  setIsChecked: any;
};

function CustomCheckbox({ isChecked, setIsChecked }: Props) {
  return (
    <label className="inline-flex items-center cursor-pointer">
      <div
        className={`w-6 h-6 border-[2px] rounded-[4px]  transition-all ${
          isChecked ? 'bg-main-blue border-main-blue' : 'bg-white'
        }`}
        onClick={() => setIsChecked((prev: boolean) => !prev)}
      >
        {isChecked && (
          <div className="ml-[-1px] mt-[-2px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M10 16.4L6 12.4L7.4 11L10 13.6L16.6 7L18 8.4L10 16.4Z"
                fill="white"
              />
            </svg>
          </div>
        )}
      </div>
    </label>
  );
}

export default CustomCheckbox;
